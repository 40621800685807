import { useTheme } from "@emotion/react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import cryptoParlayBigSvg from "../../asset/crypto_parlay.svg";

export const GameSliderHomePage = () => {
  const theme = useTheme();
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const gameSliderImage = [
    "/Images/landingPage/slider1.png",
    "/Images/landingPage/slider2.png",
    "/Images/landingPage/slider3.png",
  ];

  const [bannerImage, serBannerImage] = React.useState(
    gameSliderImage[sliderIndex]
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const gameList = [
    {
      location: "/Images/landingPage/slider-sb1.png",
      title: "Biggest winner",
      label:
        "Pick several crypto that you believe will go up during the contest duration to win BIG",
      banner: "/Images/landingPage/slider1.png",
    },
    {
      location: "/Images/landingPage/slider-sb2.png",
      title: "Biggest Loser",
      label:
        "Pick several crypto that you believe will go down during the contest duration and win BIG",
      banner: "/Images/landingPage/slider2.png",
    },
    {
      location: "/Images/landingPage/slider-sb3.png",
      title: "Crypto Parlay",
      label:
        "Pick multiple crypto tokens to either go UP or DOWN. Get each section correct and win BIG!!",
      // banner: "/Images/landingPage/slider3.png",
      banner: cryptoParlayBigSvg,
    },
    {
      location: "/Images/landingPage/BiggestWinnerStock-sb.png",
      title: "Biggest winner stocks",
      label:
        "Pick the stocks you believe will that will go UP the highest percentage within a 5 minute contest",
      banner: "/Images/landingPage/stock.png",
    },
    {
      location: "/Images/landingPage/BiggestLoserStock-sb.png",
      title: "Biggest loser stocks",
      label:
        "Pick the stocks you believe will that will go DOWN the most percentage within a 5 minute contest",
      banner: "/Images/landingPage/stock.png",
    },

    // {
    //   location: "/Images/landingPage/slider-sb4.png",
    //   title: "Sports Betting",
    //   label: "",
    //   banner: "/Images/landingPage/slider4.png",
    // },
    // {
    //   location: "/Images/landingPage/slider-sb5.png",
    //   title: "Casino",
    //   label: "",
    //   banner: "/Images/landingPage/slider5.png",
    // },
  ];

  return (
    <>
      {!isMobile ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            // paddingLeft: 1,
            // paddingRight: 1,
            alignItems: "center",
            justifyContent: "center",
            // marginBottom: 10,
          }}
        >
          <Box
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              pl: 4,
              pr: 4,
            }}
          >
            {gameList.map((e, index) => (
              <Button
                key={index}
                sx={{
                  width: "100%",
                  // height: isTablet ? "23vh" : "25vh",
                  height: {
                    xs: "100%",
                  },
                  display: "flex",
                  flexDirection: "row",
                  background:
                    sliderIndex === index
                      ? "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)"
                      : null,

                  padding: 2.5,
                  marginY: 2,
                  borderRadius: 6,
                  boxShadow: "0 1px 5px gray",
                  textTransform: "none",
                  alignItems: "start",
                  justifyContent: "start",
                  // alignContent: "start",
                }}
                onClick={() => {
                  setSliderIndex(index);
                  serBannerImage(e.banner);
                }}
              >
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: 16,
                    objectFit: "contain",
                    alignContent: "center",
                  }}
                  src={e?.location}
                  alt="game"
                  loading="lazy"
                />

                <Box
                  sx={{
                    // width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    pl: 2.5,
                    alignItems: "flex-start",
                    // backgroundColor: "black",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    fontSize={isTablet ? 14 : 20}
                    lineHeight={2}
                    color={"white"}
                    textAlign="left" // Align text to the left
                  >
                    {e.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize={isTablet ? 12 : 16}
                    color={"white"}
                    textAlign="left"
                  >
                    {e.label}
                  </Typography>
                </Box>
              </Button>
            ))}
          </Box>

          <img
            style={{
              width: "55%", // Increase size to make it larger
              height: "55%", // Adjust the height as well
              position: "relative", // Position relative to its normal position
              zIndex: 1, // Bring it above other content
              // top: "-30px", // Move the image upwards
              left: "30px", // Adjust as needed to align properly
              // transform: "rotateY(1deg) rotateZ(-5deg) skewX(-5deg)",
              transformOrigin: "center",
              objectFit: "cover",
            }}
            src={bannerImage}
            alt="game"
            loading="lazy"
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // paddingLeft: 1,
            // paddingRight: 1,
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 8,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              //   pl: 4,
              //   pr: 4,
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 4,
            }}
          >
            {gameList.map((e, index) => (
              <Button
                key={index}
                sx={{
                  width: isSmall ? "75%" : isTablet ? "50%" : "80%",
                  height: isSmall ? 100 : isTablet ? 140 : 100,
                  maxHeight: 150,
                  // height: isSmall
                  //   ? "100px !important"
                  //   : isTablet
                  //   ? "140px !important"
                  //   : "100px !important",
                  display: "flex",
                  flexDirection: "row",
                  background:
                    sliderIndex === index
                      ? "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)"
                      : null,

                  padding: isSmall ? 1 : isTablet ? 2 : 2,
                  marginY: 2,
                  borderRadius: 4,
                  boxShadow: "0 1px 5px gray",
                  textTransform: "none",
                  alignItems: "start",
                  justifyContent: "start",
                }}
                onClick={() => {
                  setSliderIndex(index);
                  serBannerImage(e.banner);
                }}
              >
                <img
                  style={{
                    width: isSmall ? "80px" : isTablet ? "100px" : "100px",
                    height: isSmall ? "80px" : "100px",
                    borderRadius: 10,
                    // objectFit: "cover",
                  }}
                  src={e?.location}
                  alt="game"
                  loading="lazy"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    pl: isSmall ? 1.5 : isTablet ? 2.5 : 2,
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    // fontSize={isSmall ? 12 : { xs: 12, sm: 16 }}
                    fontSize={isSmall ? "0.8rem" : "1rem"}
                    lineHeight={2}
                    color={"white"}
                    textAlign="left" // Align text to the left
                  >
                    {e.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    // fontSize={isSmall ? 9 : { xs: 10, sm: 14 }}
                    fontSize={isSmall ? "0.6rem" : "0.8rem"}
                    color={"white"}
                    textAlign="left"
                  >
                    {e.label}
                  </Typography>
                </Box>
              </Button>
            ))}
          </Box>

          <img
            style={{
              width: "85%", // Increase size to make it larger
              height: "55%", // Adjust the height as well
              position: "relative", // Position relative to its normal position
              zIndex: 1, // Bring it above other content
              //   top: "-30px", // Move the image upwards
              //   left: "30px", // Adjust as needed to align properly
              //   marginRight: 2,
              //   marginRight: 2,
              // transform: "rotateY(1deg) rotateZ(-5deg) skewX(-5deg)",
              transformOrigin: "center",
              objectFit: "cover",
            }}
            src={bannerImage}
            alt="game"
            loading="lazy"
          />
        </Box>
      )}
    </>
  );
};

export default GameSliderHomePage;
