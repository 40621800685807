import { Grid } from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import FooterComponent from "../Landing/FooterComponent";
import LandingHeader from "../Landing/LandingHeader";
import BetaTestingPopup from "../Popups/BetaTestingPopup";

const LandingLayout = () => {
  const location = useLocation();

  console.log(location?.pathname, "currentlocation");

  return (
    <>
      {/* <BetaTestingPopup /> */}
      <Grid className="mainPage">
        {/* <Grid container sx={{ width: "100%" }}> */}

        {location?.pathname !== "/" && (
          <nav style={{ width: "100%" }}>
            <LandingHeader fromOtherPage={true} />
          </nav>
        )}

        {/*  */}
        <main style={{ width: "100%", position: "relative" }}>
          <Outlet />
        </main>
        <footer style={{ width: "100%" }}>
          <FooterComponent />
        </footer>
        {/* </Grid> */}
      </Grid>
    </>
  );
};

export default LandingLayout;
