import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
  tooltipClasses,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { getWalletActivity } from "../../Services/Service";
import { useInfiniteQuery } from "react-query";
// import Loader from "../Loader/Loader";
import moment from "moment";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import { useTheme } from "@emotion/react";
import CryptoTooltip from "../Tooltip/LightToolTip";

const RecentTransactions = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchRecentTransactions = async ({ pageParam = 1 }) => {
    await delay(600); // Simulate a delay of 2 seconds (2000 milliseconds)
    const response = await getWalletActivity({ page: pageParam, limit: 10 });
    return response.data?.data;
  };

  const {
    data,
    isSuccess,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    // isLoading,
    // error,
    isFetchingNextPage,
  } = useInfiniteQuery("walletActivity", fetchRecentTransactions, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.page < Math.ceil(lastPage.total / 10))
        return lastPage.page + 1;
      return false;
    },
    retry: true,
  });

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  // const { data, isLoading } = useQuery(
  //   ["walletActivity"],
  //   async () => {
  //     try {
  //       await delay(800); // Simulate a delay of 2 seconds (2000 milliseconds)
  //       const result = await getWalletActivity();
  //       return result.data?.data;
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  //   {
  //     refetchOnMount: true,
  //   }
  // );

  // if (isLoading) {
  //   <Loader />;
  // }

  console.log(data, "walletActivity");

  const calculateAmount = (wallet) => {
    return wallet.isIncreased
      ? wallet?.added < 0.00001
        ? // ? "+ < 0.01"
          "+ 0.01"
        : wallet?.added === 0
        ? "+ 0"
        : "+ " + parseFloat(wallet?.added.toFixed(5)) // Removes trailing zeros for non-zero values
      : wallet?.reduced < 0.01
      ? // ? "- < 0.01"
        "- 0.01"
      : wallet?.reduced === 0
      ? "- 0"
      : "- " + parseFloat(wallet?.reduced.toFixed(5)); // Removes trailing zeros for non-zero values
  };

  return (
    <Box
      sx={{
        background: "#03364C",
        borderRadius: "27px",
        paddingY: 4,
        marginTop: isSmallScreen ? 2 : 4,
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          variant="h5"
          fontWeight={500}
          sx={{ marginX: isSmallScreen ? 1 : 4, marginBottom: 2 }}
        >
          Recent Transactions
        </Typography>
      </Stack>

      <Grid container sx={{ height: "330px", overflowY: "scroll" }}>
        {isLoading ? (
          <>
            <Box sx={{ width: "100%", marginX: 2 }}>
              <Skeleton animation="wave" height={90} />
              <Skeleton animation="wave" height={90} />
              <Skeleton animation="wave" height={90} />
              <Skeleton animation="wave" height={90} />
            </Box>
          </>
        ) : data?.pages[0]?.transaction?.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Typography>There is no transactions.</Typography>
          </Box>
        ) : (
          <>
            {isSuccess && (
              <Grid item xs={12}>
                {data.pages?.map((page) =>
                  page?.transaction?.map((wallet) => (
                    // <Paper key={wallet._id}>
                    <Grid
                      container
                      key={wallet?._id}
                      padding={isSmallScreen ? 1 : 4}
                      alignItems={"center"}
                      marginTop={{ md: 0, xs: 2 }}
                    >
                      <Grid
                        item
                        xs={5}
                        lg={4}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        {wallet?.isIncreased ? (
                          <Box
                            sx={{
                              paddingX: isSmallScreen ? -12 : 1.2,
                              paddingY: isSmallScreen ? -12 : 0.1,
                              border: isSmallScreen ? 1 : 3,
                              borderRadius: 50,
                              borderColor: "#EBEBEB",
                              mr: 1,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <ArrowDownwardTwoToneIcon
                              sx={{
                                mt: 0.6,
                                height: isSmallScreen ? 10 : 30,
                                fontWeight: "900",
                              }}
                              color="success"
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              paddingX: isSmallScreen ? -12 : 1.2,
                              paddingY: isSmallScreen ? -12 : 0.1,
                              border: isSmallScreen ? 1 : 3,
                              borderRadius: 50,
                              borderColor: "#EBEBEB",
                              mr: 1,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            boxShadow={1}
                          >
                            <ArrowUpwardTwoToneIcon
                              color="error"
                              sx={{
                                mt: 0.6,
                                height: isSmallScreen ? 10 : 30,
                                fontWeight: "900",
                              }}
                            />
                          </Box>
                        )}

                        <Typography sx={{ fontSize: isSmallScreen ? 6 : 14 }}>
                          {wallet?.type === "Entry_Fees"
                            ? "Entry Fees"
                            : wallet?.type === "WithdrawalRequest"
                            ? "Withdrawal Request"
                            : wallet?.type.charAt(0).toUpperCase() +
                              wallet?.type.slice(1)}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={6} lg={3} marginLeft={1}>
                <Typography variant="caption">
                  {new Date(wallet.updatedAt).toLocaleDateString()}
                </Typography>
              </Grid> */}
                      <Grid item xs={3} lg={2}>
                        <CryptoTooltip
                          disableFocusListener
                          disableTouchListener
                          title={
                            wallet.isIncreased ? wallet?.added : wallet?.reduced
                          }
                          placement="top"
                          slotProps={{
                            popper: {
                              sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                  {
                                    marginBottom: "5px",
                                  },
                              },
                            },
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: wallet.isIncreased ? "#09D780" : "#EF4A66",
                              fontWeight: 500,
                              fontSize: isSmallScreen ? 12 : 18,
                            }}
                          >
                            {/* {wallet.isIncreased
                            ? "+ " + wallet?.added.toFixed(2) 
                            : "- " + wallet?.reduced.toFixed(2)} */}
                            {calculateAmount(wallet)}
                          </Typography>
                        </CryptoTooltip>
                      </Grid>
                      {/* <Grid item xs={6} lg={2}>
                <Typography
                  color={wallet.status === "completed" ? "green" : "red"}
                  variant="caption"
                  sx={{ fontWeight: "bold" }}
                >
                  {wallet.status.charAt(0).toUpperCase() +
                    wallet.status.slice(1)}
                </Typography>
              </Grid> */}

                      <Grid item xs={4} lg={4}>
                        <Typography
                          sx={{
                            fontSize: { xs: 6, md: 12 },
                          }}
                        >
                          {wallet?._id}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} lg={2}>
                        <Typography
                          variant="subtitle2"
                          sx={{ mt: 1, fontSize: isSmallScreen ? 8 : 12 }}
                        >
                          {moment(wallet.createdAt).fromNow()}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} lg={6}>
                        <Typography
                          variant="subtitle2"
                          sx={{ mt: 1, fontSize: isSmallScreen ? 6 : 12 }}
                        >
                          {wallet?.walletAddress}
                        </Typography>
                      </Grid>
                    </Grid>
                    // </Paper>
                  ))
                )}
              </Grid>
            )}
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              marginTop={2}
            >
              {isFetching && isFetchingNextPage ? (
                <CircularProgress sx={{ color: "#11B1F4" }} size={24} />
              ) : hasNextPage ? (
                <Button
                  sx={{
                    paddingY: "10px",
                    color: "#11B1F4",
                    fontWeight: 400,
                    paddingX: "25px",
                    textTransform: "none",

                    // background:
                    //   "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                    // "&:hover": {
                    //   backgroundColor: " #10ADEF95",
                    // },
                  }}
                  variant="text"
                  onClick={fetchNextPage}
                >
                  + Load More
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default RecentTransactions;
