import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllCampaingData } from "../../Services/Service";
import PageLoader from "../Loaders/PageLoader";
import CampaignTimer from "../Timer/CampaignTimer";
import { prizes } from "../../Utils/prizes";
import InfoIcon from "@mui/icons-material/Info";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "transparent",
  padding: "20px",
  fontSize: "16px",
  fontWeight: "400",
  // "#12161d",
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#272850",
  width: "100%",
  marginTop: "10px",
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
  background: "transparent",
}));

const CampaignTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortType, setSortType] = useState("Referrals");
  const [currentTab, setCurrentTab] = useState("0");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [total, setTotal] = useState(0);

  const tabs = ["Referrals", "Wins"];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
    setSortType(tabs[e.target.id]);
  };

  useEffect(() => {
    const fetchAllCampaignDatas = async () => {
      try {
        setLoading(true);
        const response = await getAllCampaingData(sortType, page + 1, limit);
        setCampaignData(response.data.data?.data);
        // setTotal(response.data?.data?.totalItems);
        setTotal(Math.min(response.data?.data?.totalItems, 25)); // Limiting total to 25
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchAllCampaignDatas();
  }, [sortType, page, limit]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  return (
    <Container
      sx={{
        width: "100%",
        marginTop: { md: 10 },
      }}
    >
      <Box
        sx={{
          marginBottom: { md: 2 },
        }}
      >
        <Typography
          variant="caption"
          fontSize={{ md: "16px", sm: "30px", xs: "24px" }}
          fontFamily={"Space Grotesk"}
          fontWeight={500}
          sx={{ color: "#FFFFFF" }}
        >
          Game Time
        </Typography>
      </Box>
      <Grid container spacing={{ md: 5, xs: 2 }}>
        <Grid item xs={12} md={7}>
          {" "}
          <Typography
            variant="caption"
            fontSize={{ md: "50px", sm: "30px", xs: "26px" }}
            fontFamily={"Space Grotesk"}
            fontWeight={500}
            lineHeight={1.2}
            sx={{ color: "#FFFFFF" }}
          >
            Earn CHAMPS tokens by competing against others
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          {/* <Typography
            sx={{
              fontFamily: "Space Grotesk",
              color: "#FFFFFF",
              fontSize: { md: "18px", xs: "14px" },
              lineHeight: 2,
              fontWeight: 400,
              width: "100%",
              textAlign: isMobile && "center",
              marginY: 2,
            }}
          >
            Enter the exciting world of Fantasy Crypto where you can be a Winner
            or Loser and still WIN. Put your crypto skills to the test
          </Typography> */}
        </Grid>

        {/* table */}

        <Box
          sx={{
            marginY: "20px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingX: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              //   alignItems: "center",
              marginTop: isMobile ? 0 : 4,
              flexDirection: "column",
              width: "1000px",
              overflow: "auto",
              justifyContent: "center",
              background: "#171841",
              borderRadius: "22px",
              paddingY: 2,
              paddingX: isMobile ? 2 : 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "start" : "center",
                justifyContent: isMobile ? "center" : "space-between",
                gap: isMobile ? 4 : 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "start" : "center",
                  gap: "14px",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 22,
                    fontFamily: "Space Grotesk",
                  }}
                >
                  All Games
                </Typography>
                <Divider
                  sx={{ height: 24 }}
                  style={{
                    backgroundColor: "white",
                    display: isMobile ? "none" : "block",
                  }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />

                <div
                  style={{ display: "flex", alignItems: "center", gap: "14px" }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: 14,
                      fontFamily: "Space Grotesk",
                    }}
                  >
                    SORT BY
                  </Typography>

                  <Box
                    sx={{
                      background: "#080924",
                      padding: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      height: isMobile ? 20 : 30,
                      width: isMobile ? "140px" : "200px",
                    }}
                    gap={0.2}
                  >
                    {tabs.map((tabName, index) => (
                      <Button
                        key={index}
                        id={index}
                        disabled={currentTab === `${index}`}
                        onClick={handleTabClick}
                        sx={{
                          color: "white",
                          width: "150px",
                          height: isMobile ? 20 : 28,
                          borderRadius: "5px",
                          fontSize: isMobile ? 7 : 14,
                          "&.Mui-disabled": {
                            background: "#272850",
                            color: "white",
                          },
                          textTransform: "none",
                        }}
                      >
                        {tabName}
                      </Button>
                    ))}
                  </Box>
                </div>
              </div>

              {/* <CampaignTimer target={new Date("2024-04-12 12:00")} /> */}
            </Box>
            <TableContainer>
              {loading ? (
                <div style={{ width: "100%", marginTop: "20px" }}>
                  <PageLoader />
                </div>
              ) : (
                <>
                  {campaignData?.length === 0 ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingY: 10,
                        }}
                      >
                        <Typography sx={{ color: "white" }}>
                          There are no records to display.
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Table
                      sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0px 20px",
                        width: "100%",
                      }}
                    >
                      <StyledTableHead>
                        <TableRow>
                          <StyledTableCell>Rank</StyledTableCell>
                          <StyledTableCell>Player</StyledTableCell>
                          {/* <StyledTableCell>Points</StyledTableCell> */}
                          <StyledTableCell>{sortType}</StyledTableCell>
                          <StyledTableCell
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {/* Prize{" "} */}
                            {sortType === tabs[0] ? "Champs" : "Prize"}
                            <span
                              style={{ marginTop: "6px", cursor: "pointer" }}
                            >
                              <Tooltip
                                title={
                                  sortType === tabs[0]
                                    ? "Total champs"
                                    : // : "Prize value calculated @TGE price"
                                      "Prize value calculated in USDC price"
                                }
                                placement="top"
                                arrow
                                slotProps={{
                                  popper: {
                                    sx: {
                                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                        {
                                          marginTop: "0px",
                                        },
                                      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                        {
                                          marginBottom: "10px",
                                        },
                                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                        {
                                          marginLeft: "0px",
                                        },
                                      [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                        {
                                          marginRight: "0px",
                                        },
                                    },
                                  },
                                }}
                              >
                                <InfoIcon />
                              </Tooltip>
                            </span>
                          </StyledTableCell>
                        </TableRow>
                      </StyledTableHead>

                      <TableBody>
                        {campaignData?.map((row, index) => (
                          <StyledTableRow key={index}>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                fontSize: 26,
                                fontWeight: 500,
                                paddingLeft: 4,
                                borderBottomLeftRadius: "16px",
                                borderTopLeftRadius: "16px",
                              }}
                            >
                              {/* {index + 1} */}
                              {page * limit + index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                sx={{ width: 40, height: 40 }}
                                alt="Travis Howard"
                                src={row.user?.profilePic}
                              />

                              <Typography
                                fontWeight={400}
                                fontSize={16}
                                marginX={1}
                              >
                                {row.user.username}
                              </Typography>
                            </TableCell>
                            {/* <TableCell sx={{ borderBottom: "none" }}>
                              <span style={{ marginLeft: "5px" }}>
                                {row?.points}
                              </span>
                            </TableCell> */}
                            <TableCell sx={{ borderBottom: "none" }}>
                              <span style={{ marginLeft: "20px" }}>
                                {" "}
                                {sortType === tabs[0]
                                  ? row?.referrals
                                  : row?.totalWins ?? 0}
                              </span>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                fontSize: isMobile ? 14 : 16,
                                fontWeight: 500,
                                borderBottomRightRadius: "16px",
                                borderTopRightRadius: "16px",
                              }}
                            >
                              {/* ${prizes[page * limit + index]} */}
                              {sortType === tabs[0]
                                ? `${parseFloat(
                                    row?.user?.champsToken?.toFixed(3)
                                  )}`
                                : `$${parseFloat(
                                    (row?.totalPrizeAmount ?? 0).toFixed(3)
                                  )}`}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
            />
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default CampaignTable;
