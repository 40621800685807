import {
  Alert,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip,
  // MenuItem,
  // Stack,
  // TextField,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
  useTheme,
  // useMediaQuery,
} from "@mui/material";
import copy from "clipboard-copy";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  // createBlockchainTransaction,
  getBlockChianConfig,
} from "../../Services/Service";
// import DepositePaymentMethods from "./DepositPaymentMethods";
import DepositPopup from "../../Components/Popups/DepositPopup";
// import { makeStyles } from "@mui/styles";
// import { useTheme } from "@emotion/react";
// import ConfirmDialogue from "../../Components/Modals/ConfirmDialogue";
// import toast from "react-hot-toast";
import { ethers } from "ethers";

// import USDCTokenABI from "../../Contract/USDC.json";
import Loader from "../../Components/Loader/Loader";
import NewAbi from "../../Contract/NewAbi.json";
// import InputAdornment from "@mui/material/InputAdornment";
import { AuthContext } from "../../context/AuthContext";
import { makeMagic } from "../../lib/magic";
import { QRCodeSVG } from "qrcode.react";
import magicLinkLogo from "../../asset/Magic_Link_Color.svg";
import magicWithTextLogo from "../../asset/magicWithText.svg";
import stripeLogo from "../../asset/Stripe_Logo.svg";
import { commonData } from "../../constants/common";
import toast from "react-hot-toast";

// const CARDS_OPTIONS = [
//   { value: "ViSa1", label: "**** **** **** 1212 - Jimmy Holland" },
//   { value: "ViSa2", label: "**** **** **** 2424 - Shawn Stokes" },
//   { value: "MasterCard", label: "**** **** **** 4545 - Cole Armstrong" },
// ];

// const useStyles = makeStyles({
//   customNumberField: {
//     "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//     '& input[type="number"]': {
//       "-moz-appearance": "textfield",
//     },
//   },
// });
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 500,
  },
}));

const USDCDepositePage = () => {
  // const classes = useStyles();
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const [currecy, setCurrency] = useState("");
  const [config, setConfig] = useState();
  const [contract, setContract] = useState();
  const [tokenAmount, setTokenAmount] = useState("");
  // const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  // const [wethToUsdcAmount, setWethToUsdcAmount] = useState();
  const [usdcToWethAmount, setUsdcToWethAmount] = useState();
  const [usdcAmount, setUsdcAmount] = useState();
  const [showCopied, setShowCopied] = React.useState(false);
  const [convertionLoading, setConvertionLoading] = useState(false);

  const { user } = useContext(AuthContext);

  const ARBITRUM_MAINNET_JSON_RPC_URL = "https://arbitrum.llamarpc.com";
  const CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET =
    "0xc873fEcbd354f5A56E00E710B90EF4201db2448d";
  const WETH_ARBITRUM_MAINNET = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
  const USDCe_ARBITRUM_MAINNET = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";

  useEffect(() => {
    setUsdcAmount(
      user?.walletData?.find((item) => item._id?.paymentMethod === "USDC")
    );
  }, [user]);

  // const PAYMENT_OPTIONS = [
  //   {
  //     value: "crypto_wallet",
  //     title: "Metamask Wallet",
  //     description: `Balance :  ${
  //       usdcAmount?.totalAmount ? usdcAmount?.totalAmount?.toFixed(2) : 0
  //     } USDC`,
  //     icon: "/Images/crypto-wallet.png",
  //   },
  // ];

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const { data, isLoading } = useQuery(
    ["walletConfig"],
    async () => {
      try {
        await delay(200); // Simulate a delay of 2 seconds (2000 milliseconds)
        const result = await getBlockChianConfig();
        setContract(
          result.data?.paymentMethod?.find((item) => item.symbol === "USDC")
        );
        return result.data;
      } catch (error) {
        // seth2hLoading(false);
        console.log(error);
      }
    },
    {
      refetchOnMount: true,
    }
  );
  useEffect(() => {
    setConfig(data);
  }, [data]);

  console.log(config, "config");

  console.log(contract, "contract");
  // const handleTokenAmountChange = (event) => {
  //   setTokenAmount(event.target.value);
  // };

  // const handleDialogOpen = () => {
  //   setDialogOpen(true);
  // };

  // const handleDialogClose = () => {
  //   setDialogOpen(false);
  // };

  const convertUsdcToWeth = async (amount) => {
    try {
      if (Number(amount) !== 0) setConvertionLoading(true);
      const path = [USDCe_ARBITRUM_MAINNET, WETH_ARBITRUM_MAINNET];
      const provider = new ethers.providers.JsonRpcProvider(
        ARBITRUM_MAINNET_JSON_RPC_URL
      );
      const camelotRouter = new ethers.Contract(
        CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
        NewAbi,
        provider
      );

      console.log(1);

      const tokenAmountt = ethers.utils.parseUnits(amount ? amount : "0", 6);
      await camelotRouter.functions
        .getAmountsOut(tokenAmountt, path)
        .then((amounts) => {
          console.log(2);
          const [wethIn, usdcOut] = amounts[0];
          console.log(wethIn);
          console.log(
            "Amount of USDCe from WETH: ",
            ethers.utils.formatUnits(usdcOut, 18)
          );
          console.log(3);
          return setUsdcToWethAmount(ethers.utils.formatUnits(usdcOut, 18));
        })
        .finally(() => setConvertionLoading(false));
    } catch (error) {
      console.log(error);
      setConvertionLoading(false);
    }
  };

  useEffect(() => {
    convertUsdcToWeth(tokenAmount);
  }, [tokenAmount]);

  console.log(convertionLoading, "convertionLoading");
  console.log(usdcToWethAmount, "usdcToWethAmount");

  // const handleTransfer = async (e) => {
  //   e.preventDefault();
  //   // if (currecy === "") return toast.error("Please choose a token!");
  //   setDialogOpen(false);
  //   const notification = toast.loading("Depositing your tokens...");
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   await provider.send("eth_requestAccounts", []);
  //   const signer = await provider.getSigner();
  //   const usdcContract = new ethers.Contract(
  //     contract?.contractAddress,
  //     USDCTokenABI,
  //     signer
  //   );

  //   // if (currecy === "USDC") {
  //   try {
  //     const transaction = await usdcContract.transfer(
  //       "0xDe40A2025D6D33275EaAcC57410aC8E7c57026cD",
  //       tokenAmount * 1e6,
  //       {
  //         gasLimit: 300000,
  //       }
  //     );
  //     await transaction.wait();
  //     console.log("Transaction hash:", transaction);
  //     if (transaction.hash) {
  //       const dataOne = {
  //         walletAddress: transaction.from,
  //         userId: sessionStorage.getItem("userId"),
  //         token: (Number(tokenAmount) - Number(tokenAmount) * 0.02).toString(),
  //         transactionHash: transaction.hash,
  //         paymentMethod: "USDC",
  //         transactionType: "Deposit",
  //         exchangedToken: tokenAmount,
  //       };
  //       const res = await createBlockchainTransaction(dataOne);
  //       console.log(res);
  //       toast.success("Token deposited successfully!", { id: notification });
  //       setIsSuccessDialogOpen(true);
  //       return res;
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     toast.error("Whoops something went wrong!", {
  //       id: notification,
  //     });
  //   }
  //   // } else {
  //   // try {
  //   //   const transaction = await usdcContract.transfer(
  //   //     config?.adminWallet,
  //   //     ethers.utils.parseUnits(tokenAmount ? tokenAmount : "0").toString(),
  //   //     {
  //   //       gasLimit: 300000,
  //   //     }
  //   //   );
  //   //   await transaction.wait();
  //   //   console.log("Transaction hash:", transaction);
  //   //   if (transaction.hash) {
  //   //     const dataOne = {
  //   //       walletAddress: transaction.from,
  //   //       userId: sessionStorage.getItem("userId"),
  //   //       token: wethToUsdcAmount,
  //   //       transactionHash: transaction.hash,
  //   //       paymentMethod: currecy,
  //   //       transactionType: "Deposit",
  //   //       exchangedToken: tokenAmount,
  //   //     };
  //   //     console.log(dataOne);
  //   //     const res = await createBlockchainTransaction(dataOne);
  //   //     console.log(res);
  //   //     toast.success("Token deposited successfully!", { id: notification });
  //   //     setIsSuccessDialogOpen(true);
  //   //     return res;
  //   //   }
  //   // } catch (e) {
  //   //   console.log(e);
  //   //   toast.error("Whoops something went wrong!", {
  //   //     id: notification,
  //   //   });
  //   // }
  //   // }
  //   // }
  // };

  const handleCopyReferralLink = async () => {
    copy(user?.customWalletAddress)
      .then(() => {
        console.log("Text copied to clipboard:", user?.customWalletAddress);
        setShowCopied(true);
        setTimeout(() => {
          setShowCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  const handleDeposit = async (e) => {
    try {
      // toast.success("Coming Soon");
      await makeMagic.wallet.showOnRamp();
    } catch (error) {
      console.log(error);
    }
    // await makeMagic.wallet.showSendTokensUI();
    // await makeMagic.wallet.showAddress();
    // await makeMagic.wallet.showUI();
    // const data = await makeMagic.user.getInfo();
    // console.log(data);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DepositPopup
        open={isSuccessDialogOpen}
        setOpen={setIsSuccessDialogOpen}
        amount={tokenAmount}
      />

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            width={{ sm: "100%" }}
            sx={{
              overflow: "hidden",
              background: "#03364C",
              borderRadius: "27px",
              // padding: 4,
              // height: "100%",
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // gap: 2,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{
                paddingX: 4,
                mb: 2,
                fontWeight: 700,
                textAlign: "left",
                fontSize: isMobile ? 16 : 20,
              }}
            >
              Deposit Address
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // gap: 1,
              }}
            >
              <Typography sx={{ mr: 2 }}>{"Powered by "}</Typography>
              {/* <img src={magicLinkLogo} width="8%" height="8%" /> */}
              <img
                src={magicWithTextLogo}
                width="18%"
                height="18%"
                style={{ marginRight: 10 }}
              />
              {/* <Typography sx={{ ml: 2 }}>+</Typography> */}
              <Typography fontSize={20} fontWeight={"bold"} sx={{ mr: 1 }}>
                {"+ "}
              </Typography>
              <img src={stripeLogo} width="13%" height="14%" />
            </Box>
            <Box
              padding={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                gap: { xs: 2, sm: 2, md: 5 },
                marginTop: { xs: 2, sm: 3 },
              }}
            >
              {/* <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              > */}
              <QRCodeSVG
                value={user?.customWalletAddress}
                size={128}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={false}
              />
              {/* Wallet Address : {user?.customWalletAddress} */}

              <Alert variant="outlined" severity="info">
                {/* Only Deposit USDC on Arbitrum Network <br /> */}
                Only Deposit USDC on Polygon Network <br />
                <span style={{ fontSize: "10px", color: "gold" }}>
                  {/* USDC Address: 0x9CEb8d3f79DDba7fB11eFe17e24ED6F5463CE209 */}
                  USDC Address: {commonData.USDC_CONTRACT_ADDRESS}
                </span>
              </Alert>
              <OutlinedInput
                // fullWidth
                sx={{
                  "& fieldset": { border: "none" },
                  backgroundColor: "#011F2D",
                  borderRadius: "8px",
                  width: "100%",
                  height: isMobile ? "40px" : "50px",
                }}
                inputProps={{
                  style: {
                    fontSize: isMobile ? 16 : 18,
                  },
                }}
                name="referral"
                value={user?.customWalletAddress}
                endAdornment={
                  <InputAdornment position="end">
                    <ClickAwayListener
                      sx={{
                        background: "#011F2D",
                      }}
                    >
                      <LightTooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        open={showCopied}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Copied"
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleCopyReferralLink}
                          edge="start"
                        >
                          <ContentCopyIcon
                            sx={{ color: "#11B1F4" }}
                            fontSize="small"
                          />
                        </IconButton>
                      </LightTooltip>
                    </ClickAwayListener>
                  </InputAdornment>
                }
              />
              {/* </Typography> */}
            </Box>
            <Stack
              paddingX={2}
              marginTop={2}
              width="100%"
              sx={{
                direction: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  color: "white",
                  // width: "100%",
                  paddingY: 2,
                  paddingX: 2,
                  borderRadius: "8px",
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                  textTransform: "none",
                  fontWeight: 600,
                }}
                // disabled={true}
                onClick={async () => {
                  await handleDeposit();
                }}
              >
                Purchase Crypto
                {/* Coming Soon */}
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      {/* <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            width={{ sm: "100%" }}
            sx={{
              overflow: "hidden",
              background: "#03364C",
              borderRadius: "27px",
              // padding: 4,
              // height: "100%",
              paddingY: 4,
              display: "flex",
              flexDirection: "column",
              // gap: 2,
            }}
          >
            <Typography variant="h5" fontWeight={500} sx={{ paddingX: 2 }}>
              Deposit
            </Typography>

            <Box padding={2}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  marginBottom: 1,
                }}
              >
                Enter Amount
              </Typography>

              <Box
                display={"flex"}
                sx={{
                  background: "#011F2D",
                  borderRadius: "8px",

                  padding: isSmallScreen ? 0 : 1,
                }}
              >
                <TextField
                  type="number"
                  fullWidth
                  variant="outlined"
                  // className={classes.customNumberField}
                  value={tokenAmount}
                  onChange={handleTokenAmountChange}
                  placeholder="Enter token amount"
                  inputProps={{
                    style: {
                      color: "white",
                      width: "100%",
                      fontWeight: 600,
                      fontSize: isSmallScreen ? 12 : 16,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          sx={{
                            fontSize: isSmallScreen ? 12 : 16,
                          }}
                        >
                          {contract?.symbol}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& fieldset": { border: "none" },
                    backgroundColor: "#011F2D",
                    borderRadius: "8px",
                    width: "100%",
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              </Box>
            </Box>

            <DepositePaymentMethods
              cardOptions={CARDS_OPTIONS}
              paymentOptions={PAYMENT_OPTIONS}
              isheaderShow={true}
            />

            <Box paddingX={2}>
              <Typography
                variant="h5"
                fontWeight={500}
                sx={{ marginBottom: 2 }}
              >
                Deposit Summary
              </Typography>

              <Box
                sx={{
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                  height: { md: 65, xs: 50 },
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    fontWeight: 500,
                    fontSize: isSmallScreen ? 14 : 22,
                  }}
                >
                  {tokenAmount || 0}
                  <Typography
                    variant="caption"
                    marginLeft={1}
                    sx={{
                      fontSize: isSmallScreen ? 8 : 12,
                    }}
                  >
                    USDC =
                  </Typography>
                </Typography>

                {convertionLoading ? (
                  <Typography marginLeft={1}>Loading...</Typography>
                ) : (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      fontWeight: 500,
                      fontSize: isSmallScreen ? 12 : 22,
                      marginLeft: 1,
                    }}
                  >
                    {isNaN(usdcToWethAmount)
                      ? 0
                      : Number(usdcToWethAmount).toFixed(5)}
                    <Typography variant="caption" marginLeft={1}>
                      ETH
                    </Typography>
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                background: "#011F2D",
                borderRadius: "19px",
                padding: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginTop: 3,
                marginX: 2,
                paddingX: { md: 2 },
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Frequency
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  One Time
                </Typography>
              </Stack>

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Amount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  ${tokenAmount ? Number(tokenAmount).toFixed(2) : 0.0}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Transaction Charges
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={400}
                  color={"gray"}
                >
                  {config?.transactionCharge}%
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                >
                  Total Amount
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={{ sm: 12, md: 14 }}
                  fontWeight={600}
                  color={"white"}
                >
                  {tokenAmount
                    ? (
                        Number(tokenAmount) -
                        (Number(tokenAmount) * config?.transactionCharge) / 100
                      ).toFixed(2)
                    : 0.0}
                </Typography>
              </Stack>
            </Box>

            <Box paddingX={2} marginTop={4}>
              <Button
                sx={{
                  color: "white",
                  width: "100%",
                  paddingY: 2,
                  borderRadius: "8px",
                  background:
                    "linear-gradient(to right, #11B1F4 0%, #085A7D  100%, #085A7D 100%)",
                  textTransform: "none",
                  fontWeight: 600,
                }}
                onClick={() => handleDialogOpen()}
                disabled={!tokenAmount}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Grid>

        <ConfirmDialogue
          open={isDialogOpen}
          handleClose={handleDialogClose}
          title="Confirmation"
          content={`You want to deposit $${tokenAmount} USDC ?`}
          handleConfirm={handleTransfer}
        />
      </Grid> */}
    </>
  );
};

export default USDCDepositePage;
